import React, { Component } from 'react';
import axios from 'axios'
import './App.css';

class App extends Component {
  constructor() {
    super()
    this.state = {
      users: []
    }
  }
  componentWillMount() {
    axios.get('https://www.sspot-app.net/ParkingCandidate/').then(response => {
      const key = 'creatorId';
      const filteredArray = response.data.filter(parking => {
        return parking.status === 'ENABLED';
      });
      let objectUsers = filteredArray.map(parking => {
        const keyName = parking.creator ? parking.creator.firstName + ' ' + parking.creator.lastName : 'Sin nombre';
        parking[key] = parking.creator ? parking.creator.id + '.' + keyName : '0.' + keyName;
        return parking;
      }).reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
      let topUsers = [];
      for (const prop in objectUsers) {
        const newObjUser = {
          userName: prop.split('.')[1],
          numberOfParkings: objectUsers[prop].length
        };
        topUsers.push(newObjUser);
      }
      topUsers.sort(function (a, b) {
        return parseFloat(a.numberOfParkings) - parseFloat(b.numberOfParkings);
      }).reverse();
      this.setState({ users: topUsers });
    });
  }
  render() {
    return (
      <div className="App">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Número de parqueaderos</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.users.map((user, i) => (
                <tr key={'user' + i}>
                  <td>{user.userName}</td>
                  <td>{user.numberOfParkings}</td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </div>
    );
  }
}

export default App;
